import React from 'react';
import dogTagMint from '../../assets/yourInfo/dog.svg';
import dogTagNavy from '../../assets/yourInfo/dog-tag-navy.svg';
import catTagMint from '../../assets/yourInfo/cat.svg';
import catTagNavy from '../../assets/yourInfo/cat-tag-navy.svg';
import { PetType, TAG_COLOR } from '../../constants';

const TagPetCover = ({ petTagColor, petType }) => {
  function getTagImagen() {
    if (petType === PetType.Dog.value) {
      return petTagColor === TAG_COLOR.mintColor ? dogTagMint : dogTagNavy;
    }

    return petTagColor === TAG_COLOR.mintColor ? catTagMint : catTagNavy;
  }

  return <img alt="" aria-hidden src={getTagImagen()} />;
};

export default TagPetCover;
