import React from 'react';
import './InputErrorLabel.css';

const InputErrorLabel = ({
  className = '',
  error,
}) => (
  <span
    aria-live="polite"
    className={`Input-error-label-error ${className}`}
  >
    {error}
  </span>
);

export default InputErrorLabel;
