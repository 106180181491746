import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import figoLogo from '../../../assets/figo-logo.svg';

import { PetQuoteDropDown }
from '../../mobile/petQuotingDropDown/PetQuoteDropDown';
import { useOnBodyScrollEvent } from '../../../hooks';

import './css/AnimatedFloatingHeader.css';

const AnimatedFloatingHeader = ({ isSecondStep = false }) => {
  const store = useSelector(({ quoting }) => quoting);
  const { data } = store;

  const petQuoteList = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.ebPetQuoteResponseList || [];
  }, [data]);

  const { verticalScrollValue } = useOnBodyScrollEvent();
  const showFloating = verticalScrollValue < -245;

  const headerClassFixed = showFloating
    ? 'Animated-floating-header-full-width-fixed '
    + 'Animated-floating-header-full-width-big'
    : 'Animated-floating-header-full-width-unset';
  const hideFigoLogo = showFloating ? 'Animated-floating-header-no-mobile' : '';

  return (
    <>
      <nav
        aria-label="Section header"
        className={'Quoting-header-container'
          + ' Animated-floating-header-container-no-sticky'}
      >
        <div
          className={`${'Quoting-header-container-row-top '
            + 'Animated-floating-header-full-width '}`}
        >
          <img
            alt="Figo Logo"
            className="Animated-floating-header-logo-image "
            src={figoLogo}
          />
        </div>
      </nav>

      {!showFloating && (
        <div className="Animated-floating-header-pet-selector-container">
          <div className="Animated-floating-header-card-plan-width">
            <PetQuoteDropDown
              classContainer="Animated-floating-header-desktop-visible-top"
              hideAddPet={isSecondStep}
              petQuoteList={petQuoteList}
            />
          </div>
        </div>
      )}

      {showFloating && (
        <div
          className={`${'Quoting-header-container-row-top '
            + 'Animated-floating-header-full-width '}${headerClassFixed}`}
        >
          <img
            alt="Figo Logo"
            className={`${'Animated-floating-header-logo-image '
              + 'Animated-floating-header-logo-image-fixed '}${hideFigoLogo}`}
            src={figoLogo}
          />

          <div className="Animated-floating-header-card-plan-width-floating">
            <PetQuoteDropDown
              classContainer="Animated-floating-header-desktop-visible"
              hideAddPet={isSecondStep}
              petQuoteList={petQuoteList}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { AnimatedFloatingHeader };
