import React, { useMemo } from 'react';
import { PetQuoteDropDown } from '../../mobile';
import CardPlanMainHeaderCompany from '../CardPlanMainHeaderCompany';
import './css/QuotingHeader.css';

const StickyHeader = ({ petQuoteResponseList = [], isSecondStep = false }) => {
  const petQuoteList = useMemo(() => petQuoteResponseList,
    [petQuoteResponseList]);

  return (
    <>
      <PetQuoteDropDown
        classContainer="Quoting-header-dropdown"
        customClass="Quoting-item-container"
        hideAddPet={isSecondStep}
        petQuoteList={petQuoteList}
      />

      <div className={'Quoting-header-top-card-container '
        + 'Quoting-header-no-table-big'}
      >
        <CardPlanMainHeaderCompany />
      </div>
    </>
  );
};

export { StickyHeader };
