import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { rateSummary } from '../actions';

const useRateSummary = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const {
    customerZipCode,
    data,
    effectiveDateCustom,
    guidData,
    parameters,
    plans,
    quoteSelectedValues,
    sessionInformation: { nopCommerceUser, userEmail },
  } = store;

  const getSelectedRateBody = useMemo(() => {
    if (!data) {
      return {};
    }

    const petQuotes = [];

    data.ebPetQuoteResponseList.forEach((quote) => {
      const selectedValues = quoteSelectedValues.find(
        (item) => item.quoteId === quote.petQuoteId,
      );

      if (!selectedValues) {
        return;
      }

      const selectedPackagePlan = quote.PrePackagedPlans
        .find((item) => item.Id === selectedValues.selectedPlanPackaged);

      let reimbursement = selectedValues.reimbursementId;
      let deductible = selectedValues.deductibleId;

      if (selectedPackagePlan) {
        reimbursement = selectedPackagePlan.ReimbursementId;
        deductible = selectedPackagePlan.DeductibleId;
      }

      const selectedPlanByIndex = plans[selectedValues.selectedPlanIndex];

      petQuotes.push({
        deductible,
        Id: quote.petQuoteId,
        Modifiers: quote.modifiers || [],
        PetAge: quote.petAgeName,
        PetBreed: quote.breedName,
        PetBreedId: quote.breedId,
        PetName: quote.petName,
        PetSex: quote.genderName,
        PetType: quote.petType === 0 ? 'Male' : 'Female',
        plan: selectedPlanByIndex.id,
        reimbursement,
        selectedPlanIndex: selectedValues.selectedPlanIndex,
      });
    });

    const body = {
      DiamondClientId: nopCommerceUser.DiamonClientdId,
      ebGuID: guidData.GuID,
      EffectiveDate: null,
      eMail: userEmail || parameters.email,
      employerName: guidData.Name,
      GroupCode: data.groupCode,
      GroupCodeDscr: data.groupCodeDscr,
      IsEb: true,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      petQuotes,
      quoteId: 1,
      TestEffectiveDate: effectiveDateCustom,
      ZipCode: customerZipCode,
    };

    return body;
  }, [
    data,
    guidData,
    effectiveDateCustom,
    nopCommerceUser.DiamonClientdId,
    nopCommerceUser.CustomerNopCommerceId,
    userEmail,
    parameters.email,
    customerZipCode,
    quoteSelectedValues,
    plans,
  ]);

  const getSelectedRate = useCallback(async () => {
    await dispatch(rateSummary(getSelectedRateBody));
  }, [dispatch, getSelectedRateBody]);

  return { getSelectedRate, getSelectedRateBody };
};

export { useRateSummary };
