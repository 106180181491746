import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslations } from '../../hooks/useTranslations';
import {
  CUSTOMER_SERVICE_EMAIL,
  CUSTOMER_SERVICE_MSG_NUMBER,
  CUSTOMER_SERVICE_PHONE_NUMBER,
} from '../../constants';
import './QuestionsSection.css';

const QuestionsSection = ({
  isLogin = false,
  isPurchase = false,
  isQuote = false,
}) => {
  const { t } = useTranslations('quoting', 'questionsSection');
  const store = useSelector(({ quoting }) => ({
    guidData: quoting.guidData,
    supportPhoneNumber: quoting.supportPhoneNumber,
  }), shallowEqual);
  let title = t('questions');
  const supportPhoneNumber = useMemo(() => {
    if (store.supportPhoneNumber) {
      return `${store.supportPhoneNumber}.`;
    }

    if (store.guidData) {
      return `${store.guidData.PhoneNumber}.`;
    }

    return `${CUSTOMER_SERVICE_PHONE_NUMBER}.`;
  }, [store]);

  let supportDesc = t('supportDesc');
  let supportSchedule = t('supportSchedule');
  let extraNumber = '';
  let supportDesc2 = '';
  const emailDesc = t('emailDesc');

  if (isPurchase) {
    title = t('contactUs');
    supportDesc = t('call');
    supportDesc2 = t('text');
    extraNumber = CUSTOMER_SERVICE_MSG_NUMBER;
    supportSchedule = t('supportSchedule');
  }

  return (
    <section
      aria-label={t('questionsSection')}
      className={'Questions-section-container'
        + ` ${isQuote ? 'Questions-section-innerent-quote' : ''} `
        + ` ${isPurchase ? 'Questions-section-purchase' : ''}`
        + ` ${isLogin ? 'Questions-section-login' : ''}`}
    >
      <div className="Questions-section-header">
        <h3 className="Questions-section-title">{title}</h3>
      </div>

      <div className="Questions-section-desciption">
        <p className="Questions-section-call-text">
          {supportDesc} {supportPhoneNumber}
        </p>

        {supportDesc2 && (
          <p className="Questions-section-questions-text">
            {supportDesc2} <br aria-hidden="true" /> {extraNumber}
          </p>
        )}

        <p className="Questions-section-questions-text-subtitle">
          {supportSchedule}
        </p>

        {isPurchase && (
          <p className="Questions-section-questions-text">
            {emailDesc}

            <br aria-hidden="true" />

            <a href={`mailto:${CUSTOMER_SERVICE_EMAIL}`}>
              {CUSTOMER_SERVICE_EMAIL}
            </a>
          </p>
        )}
      </div>
    </section>
  );
};

export { QuestionsSection };
