import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import moneyLogo from '../../../assets/landing/money-mag-logo.svg';
import roverLogo from '../../../assets/landing/rover-logo.svg';
import laurel from '../../../assets/landing/laurel.svg';
import './LandingRating.css';

const LandingRating = () => {
  const { t } = useTranslation('landing');
  const reviews = useMemo(() => [
    {
      date: t('rating.rover.date'),
      logo: roverLogo,
      name: t('rating.rover.label'),
      text: t('rating.rover.review'),
    },
    {
      date: t('rating.money.date'),
      logo: moneyLogo,
      name: t('rating.money.label'),
      text: t('rating.money.review'),
    },
  ], [t]);

  return (
    <section aria-label="Comments" className="Landing-rating">
      <img alt="" className="Landing-rating-left-laurel" src={laurel} />

      {reviews.map((review) => (
        <div key={review.name} className="Landing-rating-reviewer">
          <img alt={review.name} src={review.logo} />

          <p
            className="Landing-rating-review"
          >
            {review.text}
          </p>

          <p className="Landing-rating-date">
            {review.date}
          </p>
        </div>
      ))}

      <img alt="" src={laurel} />
    </section>
  );
};

export { LandingRating };
