import React from 'react';

import { isProdEnv } from '../../util';
import {
  LANDING_DISCLAIMER,
} from '../../constants';

import Footer from '../common/conversion/Footer';
import './LandingFooter.css';
import { FooterLinks } from '../common/FooterLinks';

const LandingFooter = () => (
  <footer className="Landing-footer-container">
    <Footer className="Landing-footer-copy-right" />

    <div className="Landing-footer">
      <FooterLinks />

      <div className="Landing-footer-app-logo-container">
        <p className="Landing-footer-app-logo-text">
          {LANDING_DISCLAIMER}
        </p>
      </div>
    </div>

    <span aria-hidden="true" className={isProdEnv ? 'No-display' : ''}>
      {process.env.REACT_APP_NAME}
    </span>
  </footer>
);

export default LandingFooter;
