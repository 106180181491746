import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  NEW_CUSTOMER_CITY_CHANGED,
  NEW_CUSTOMER_EMPLOYEE_ID_CHANGED,
  NEW_CUSTOMER_LAST_NAME_CHANGED,
  NEW_CUSTOMER_NAME_CHANGED,
  NEW_CUSTOMER_PHONE_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED,
  NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED,
  NEW_CUSTOMER_STATE_CHANGED,
  NEW_CUSTOMER_STREET_CHANGED,
  NEW_CUSTOMER_STREET2_CHANGED,
  NEW_CUSTOMER_ZIP_CODE_CHANGED,
  NEW_CUSTOMER_EMAIL_ERROR_CHANGED,
  NEW_CUSTOMER_EMAIL_CHANGED,
} from '../../../actions/types';
import {
  loadAddressByZipCode,
  newCustomerChanged,
  saveQuote,
  setCurrentQuoteStep,
  toggleSecondPetParentForm,
  toggleYourInfoScrollToTop,
  validateCustomerExists,
  validateCustomerExistsByEmail,
  validateDiamondUser,
} from '../../../actions';
import TouchableDiv from '../../common/TouchableDiv';
import Shimmer from '../../common/Shimmer';
import lockIcon from '../../../assets/lock-icon.svg';
import './PetParentInfo.css';
import {
  formatDate,
  isValidDiamondStreet,
  removeEmojis,
  shouldHideEmployeeField,
  shouldHideEmployeeInfoBubble,
  testContainNumber,
  testOnlyNumber,
  useFSCLeads,
  useROPC,
  useSecondaryPetParent,
  validateEmail,
} from '../../../util';
import {
  QuotingSteps,
  SAVE_QUOTE_TYPE,
  TEN_DIGIT_NUMBER,
} from '../../../constants';
import IconTooltip from '../../common/IconTooltip';
import showPasswordIcon from '../../../assets/yourInfo/show-password.svg';
import hidePasswordIcon from '../../../assets/yourInfo/hide-password.svg';
import plusIcon from '../../../assets/plus-circle-icon.svg';
import InputErrorLabel from '../../common/InputErrorLabel';
import { useB2CInfo, usePrevious, useTranslations } from '../../../hooks';
import { URL_YOUR_INFO } from '../../../routes';

const TYPES = {
  employeeID: NEW_CUSTOMER_EMPLOYEE_ID_CHANGED,
  newCustomerEmail: NEW_CUSTOMER_EMAIL_CHANGED,
  petParenStreetAddress: NEW_CUSTOMER_STREET_CHANGED,
  petParenStreetAddress2: NEW_CUSTOMER_STREET2_CHANGED,
  petParentCity: NEW_CUSTOMER_CITY_CHANGED,
  petParentFirstName: NEW_CUSTOMER_NAME_CHANGED,
  petParentLastName: NEW_CUSTOMER_LAST_NAME_CHANGED,
  petParentPhoneNumber: NEW_CUSTOMER_PHONE_CHANGED,
  petParentState: NEW_CUSTOMER_STATE_CHANGED,
  petParentZipCode: NEW_CUSTOMER_ZIP_CODE_CHANGED,
  secondaryPetParentLastName: NEW_CUSTOMER_SECOND_PARENT_LAST_NAME_CHANGED,
  secondaryPetParentMobilePhone: NEW_CUSTOMER_SECOND_PARENT_NUMBER_CHANGED,
  secondaryPetParentName: NEW_CUSTOMER_SECOND_PARENT_NAME_CHANGED,
};

const InputError = ({ error }) => (
  <span
    aria-live="polite"
    className="Pet-parent-label-error"
  >
    {error}
  </span>
);

const PetParentInfo = ({
  containerRef = null,
  containerSecondaryRef = null,
  openLogin = () => {},
  sendCustomerLead = () => { },
  validatePetParentInfo,
}) => {
  const { loginB2C } = useB2CInfo();
  const store = useSelector(({ quoting }) => (quoting), shallowEqual);
  const { t } = useTranslations('quoting');
  const {
    employeeIdHelpText,
    employerId,
    newCustomer,
    newCustomer: {
      canEditAddress,
      emailError,
    },
    sessionInformation: {
      isLoading,
      nopCommerceUser,
    },
  } = store;
  const [isMounted, setIsMounted] = useState(false);
  const [nameError, setNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [cityError, setCityError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [secondParentNameError, setSecondParentNameError] = useState('');
  const [secondLastNameError, setSecondLastNameError] = useState('');
  const [secondParentPhoneError, setSecondParentPhoneError] = useState('');
  const [employeeError, setEmployeeError] = useState('');
  const [showEmployeeID, setShowEmployeeID] = useState(false);
  const [isFirstValidation, setIsFirstValidation] = useState(true);
  const petParentNameRef = useRef();
  const emailRef = useRef(null);
  const dispatch = useDispatch();

  const isNopComercedDisabled = useMemo(() => {
    if (canEditAddress) {
      return false;
    }

    return nopCommerceUser.LoginSuccess
      && nopCommerceUser.CustomerNopCommerceId;
  }, [
    canEditAddress,
    nopCommerceUser.CustomerNopCommerceId,
    nopCommerceUser.LoginSuccess,
  ]);

  useEffect(() => {
    if (store.customerZipCode !== newCustomer.address.zipCode
      || !newCustomer.address.city) {
      dispatch(loadAddressByZipCode(store.customerZipCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, store.customerZipCode, newCustomer.address.zipCode]);

  useEffect(() => {
    if (store.scrollYourInfoToTop) {
      window.scrollTo(0, 0);
      dispatch(toggleYourInfoScrollToTop(false));
    }
  }, [store.scrollYourInfoToTop, dispatch]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      dispatch(setCurrentQuoteStep(QuotingSteps.YourInfo.value));
    }
  }, [isMounted, dispatch]);

  useEffect(() => {
    if (!nopCommerceUser.LoginSuccess || !isMounted) {
      return;
    }

    setNameError('');
    setLastNameError('');
    setStreetError('');
    setCityError('');
  }, [isMounted, nopCommerceUser]);

  useEffect(() => {
    dispatch(newCustomerChanged({
      type: NEW_CUSTOMER_EMAIL_ERROR_CHANGED,
      value: '',
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValueChanged = (event) => {
    const { id, value } = event.target;
    const cleanValue = removeEmojis(value);
    const containsNumber = testContainNumber(cleanValue);

    if (TYPES[id] === NEW_CUSTOMER_CITY_CHANGED
      && containsNumber && cleanValue) {
      return;
    }

    dispatch(newCustomerChanged({ type: TYPES[id], value: cleanValue }));
  };

  const onInputFocus = (callbackState) => () => {
    callbackState('');
  };

  const onInputFocusEmail = (type, error = '') => () => {
    dispatch(newCustomerChanged({ type, value: error }));
  };

  const onRequiredInputBlur = (errorMessage, setErrorCallBack) => (event) => {
    const { id, value } = event.target;
    dispatch(newCustomerChanged({ type: TYPES[id], value: value.trim() }));
    setErrorCallBack(value.trim() ? '' : errorMessage);
  };

  const toggleSecondaryPetParentForm = () => {
    dispatch(toggleSecondPetParentForm(!store.secondParentFormVisible));
  };

  function getStreetError(value) {
    let error = value ? '' : t('petParent.streetAddressRequired');

    if (value && !isValidDiamondStreet(value)) {
      error = t('petParent.invalidStreetError');
    }

    return error;
  }

  const onStreetInputBlur = (event) => {
    const { id, value } = event.target;

    setStreetError(getStreetError(value));
    dispatch(newCustomerChanged({ type: TYPES[id], value: value.trim() }));
  };

  const onStreetValueChanged = (event) => {
    const { id, value } = event.target;
    const cleanValue = removeEmojis(value);
    dispatch(newCustomerChanged({ type: TYPES[id], value: cleanValue }));

    setStreetError(isValidDiamondStreet(cleanValue)
      ? '' : t('petParent.invalidStreetError'));
  };

  const onSecondPhoneNumberBlur = (event) => {
    const { value } = event.target;
    const { secondParent } = newCustomer;
    const { lastName, name, phoneNumber } = secondParent;

    let error = '';

    if (name.trim() || lastName.trim() || phoneNumber.trim()) {
      error = value ? '' : t('petParent.mobileRequired');
    }

    if (value.trim().length >= 1) {
      error = TEN_DIGIT_NUMBER.test(value)
        ? '' : t('petParent.invalidPhoneError');
    }

    setSecondParentPhoneError(error);

    if (!error
      && secondParent.name && !secondParentNameError
      && secondParent.lastName && !secondLastNameError) {
      sendCustomerLead();
    }
  };

  function getPhoneError(value) {
    let error = value ? '' : t('petParent.mobileRequired');

    if (value) {
      error = TEN_DIGIT_NUMBER.test(value)
        ? '' : t('petParent.invalidPhoneError');
    }

    return error;
  }

  const onPhoneNumberBlur = (event) => {
    const { value } = event.target;
    setPhoneNumberError(getPhoneError(value));
  };

  const onPhoneNumberChanged = (event) => {
    const { id, value } = event.target;
    const onlyNumbersValue = testOnlyNumber(value)
      ? value
      : value.replace(/\D/g, '');
    dispatch(newCustomerChanged({ type: TYPES[id], value: onlyNumbersValue }));
  };

  const onEmployeeIDBlur = (event) => {
    const { id, value } = event.target;
    const error = value ? '' : t('petParent.employeeRequired');
    setEmployeeError(error);
    dispatch(newCustomerChanged({ type: TYPES[id], value: value.trim() }));
  };

  const onEmployeeChanged = (event) => {
    const { id, value } = event.target;

    dispatch(newCustomerChanged({ type: TYPES[id], value }));
  };

  const onSecondStreetAddressBlur = (event) => {
    const { id, value } = event.target;
    dispatch(newCustomerChanged({ type: TYPES[id], value: value.trim() }));
  };

  const onSecondaryPetInputBlur = (
    errorMessage,
    inputErrorCallBack,
  ) => (event) => {
    const { id, value } = event.target;
    const { secondParent } = newCustomer;
    const { lastName, name, phoneNumber } = secondParent;

    let error = '';

    if (name.trim() || lastName.trim() || phoneNumber.trim()) {
      error = value ? '' : errorMessage;
    }

    inputErrorCallBack(error);
    dispatch(newCustomerChanged({ type: TYPES[id], value: value.trim() }));
  };

  const prevEmail = usePrevious(newCustomer.email);

  const {
    customerValidationResponse,
    parameters,
    guidData,
    quoteSelectedValues,
    saveQuote: { quoteId },
  } = store;

  const {
    groupCode,
    groupCodeDscr,
    ebPetQuoteResponseList,
    zipCode,
  } = store?.data || {};

  const previousEmailValidated = usePrevious(customerValidationResponse.email);
  useEffect(() => {
    if (!isFirstValidation
      && previousEmailValidated !== customerValidationResponse.email) {
      if (useFSCLeads) {
        const cloudClientId = customerValidationResponse?.id || 0;
        const { GuID, EmployerEnrollmentPeriodEBs } = guidData;
        dispatch(saveQuote({
          cloudClientId,
          ebPetQuoteResponseList,
          EffectiveDate:
            formatDate(EmployerEnrollmentPeriodEBs[0].PolicyEffectiveDate),
          email: newCustomer.email || parameters.email,
          firstName: newCustomer.name || '',
          groupCode,
          groupCodeDscr,
          GuID,
          lastName: newCustomer.lastName || '',
          origin: window.location.origin,
          quoteId,
          quoteSelectedValues,
          QuoteSubId: store.subId,
          quoteType: SAVE_QUOTE_TYPE.auto,
          zipCode,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    customerValidationResponse.email,
    previousEmailValidated,
    isFirstValidation,
  ]);

  const onBlurEmail = async (event) => {
    const { value } = event.target;
    let error = value ? '' : t('petParent.emailRequired');

    if (value) {
      error = validateEmail(value) ? '' : t('petParent.invalidEmailError');
    }

    if (!error) {
      if (isFirstValidation || (!isFirstValidation && prevEmail !== value)) {
        const payload = await validateCustomerExistsByEmail(value);
        const { IsValid, Data } = payload;
        const userAlreadyExists = IsValid && !!Data;

        dispatch(validateDiamondUser({
          customerExistsData: Data,
          email: value,
          userAlreadyExists,
          validateCustomer: false,
        }));

        if (userAlreadyExists) {
          if (useROPC && !isNopComercedDisabled) {
            openLogin();
          } else if (!useROPC) {
            loginB2C({
              existingEmail: value,
              urlState: URL_YOUR_INFO,
            });
          }
        }

        setIsFirstValidation(false);
      }
    }

    dispatch(newCustomerChanged({
      type: NEW_CUSTOMER_EMAIL_ERROR_CHANGED,
      value: error,
    }));
  };

  function renderSecondaryPetParentForm() {
    if (store.secondParentFormVisible) {
      return (
        <div
          ref={containerSecondaryRef}
          className="Second-parent-form-container"
          id="secondaryParentInfo"
        >
          <div className="Pet-parent-input-row">
            <div className={'Pet-parent-input-container '
              + 'Pet-parent-input-container-left'}
            >
              <label
                className="Pet-parent-input-label"
                htmlFor="secondaryPetParentName"
              >
                {t('petParent.firstName')}

                <input
                  className={'Pet-parent-input '
                    + `${secondParentNameError
                      ? 'Pet-parent-input-error' : ''}`}
                  id="secondaryPetParentName"
                  name="secondaryParentName"
                  onBlur={onSecondaryPetInputBlur(
                    t('petParent.firstNameRequired'), setSecondParentNameError,
                  )}
                  onChange={onValueChanged}
                  onFocus={onInputFocus(setSecondParentNameError)}
                  placeholder={t('petParent.required')}
                  type="text"
                  value={newCustomer.secondParent.name}
                />
              </label>

              <InputError error={secondParentNameError} />
            </div>

            <div className="Pet-parent-input-container">
              <label
                className="Pet-parent-input-label"
                htmlFor="secondaryPetParentLastName"
              >
                {t('petParent.lastName')}

                <input
                  className={'Pet-parent-input '
                    + `${secondLastNameError ? 'Pet-parent-input-error' : ''}`}
                  id="secondaryPetParentLastName"
                  name="secondaryParentLastName"
                  onBlur={onSecondaryPetInputBlur(
                    t('petParent.lastNameRequired'), setSecondLastNameError,
                  )}
                  onChange={onValueChanged}
                  onFocus={onInputFocus(setSecondLastNameError)}
                  placeholder={t('petParent.required')}
                  type="text"
                  value={newCustomer.secondParent.lastName}
                />
              </label>

              <InputError error={secondLastNameError} />
            </div>
          </div>

          <div className="Pet-parent-input-row">
            <div className={'Pet-parent-input-container '
              + 'Pet-parent-input-container-left'}
            >
              <label
                className="Pet-parent-input-label"
                htmlFor="secondaryPetParentMobilePhone"
              >
                {t('petParent.mobilePhone')}

                <input
                  className={'Pet-parent-input '
                    + `${secondParentPhoneError
                      ? 'Pet-parent-input-error' : ''}`}
                  id="secondaryPetParentMobilePhone"
                  maxLength={10}
                  name="secondaryParentMobilePhone"
                  onBlur={onSecondPhoneNumberBlur}
                  onChange={onPhoneNumberChanged}
                  onFocus={onInputFocus(setSecondParentPhoneError)}
                  placeholder={t('petParent.required')}
                  type="text"
                  value={newCustomer.secondParent.phoneNumber}
                />
              </label>

              <InputError error={secondParentPhoneError} />
            </div>

            <div className="Pet-parent-input-container" />
          </div>
        </div>
      );
    }

    return null;
  }

  function renderSecondaryPetParentToggle() {
    if (useSecondaryPetParent) {
      return (
        <div className="Second-parent-button-span-container">
          <button
            aria-controls="secondaryParentInfo"
            aria-expanded={store.secondParentFormVisible}
            className="Second-parent-button-container Like-span"
            id="secondaryPetParentButtonContainer"
            onClick={toggleSecondaryPetParentForm}
            type="button"
          >
            <img
              alt=""
              className="Second-parent-plus-icon"
              id="secondaryPetParentButtonPlusIcon"
              src={plusIcon}
            />

            <span
              className="Second-parent-button-title"
              id="secondaryPetParentButtonTitle"
            >
              {t('petParent.secondaryPetParent')}
            </span>
          </button>

          <span
            className="Second-parent-brief-description"
          >
            {t('petParent.secondaryDescription')}
          </span>
        </div>
      );
    }

    return null;
  }

  const toogleShowEmployeeID = useCallback(() => {
    setShowEmployeeID(!showEmployeeID);
  }, [showEmployeeID]);

  function validateFields() {
    const nameErrorLocal = newCustomer.name
      ? '' : t('petParent.firstNameRequired');
    setNameError(nameErrorLocal);

    const lastNameErrorLocal = newCustomer.lastName
      ? '' : t('petParent.lastNameRequired');
    setLastNameError(lastNameErrorLocal);

    const streetErrorLocal = getStreetError(newCustomer.address.street);
    setStreetError(streetErrorLocal);

    const cityErrorLocal = newCustomer.address.city
      ? '' : t('petParent.cityRequired');
    setCityError(cityErrorLocal);

    const phoneNumberErrorLocal = getPhoneError(newCustomer.phoneNumber);
    setPhoneNumberError(phoneNumberErrorLocal);

    const employeeErrorLocal = newCustomer.employeeID
      ? '' : t('petParent.employeeRequired');
    setEmployeeError(employeeErrorLocal);

    if (!nameErrorLocal && !lastNameErrorLocal && !streetErrorLocal
      && !cityErrorLocal && !phoneNumberErrorLocal && !employeeErrorLocal) {
      if (store.secondParentFormVisible) {
        const secondParentNameErrorLocal = newCustomer.secondParent.name
          ? '' : t('petParent.firstNameRequired');
        setSecondParentNameError(secondParentNameErrorLocal);

        const secondLastNameErrorLocal = newCustomer.secondParent.lastName
          ? '' : t('petParent.lastNameRequired');
        setSecondLastNameError(secondLastNameErrorLocal);

        const secondPhoneErrorLocal =
          getPhoneError(newCustomer.secondParent.phoneNumber);
        setSecondParentPhoneError(secondPhoneErrorLocal);

        if (!secondParentNameErrorLocal
          && !secondLastNameErrorLocal
          && !secondPhoneErrorLocal) {
          dispatch(validateCustomerExists(newCustomer.email));
        }
      } else {
        dispatch(validateCustomerExists(newCustomer.email));
      }
    }
  }

  useEffect(() => {
    if (validatePetParentInfo > 0) {
      validateFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validatePetParentInfo]);

  useEffect(() => {
    if (shouldHideEmployeeField(employeeIdHelpText)) {
      dispatch(newCustomerChanged({ type: TYPES.employeeID, value: 'N/A' }));
    }
  }, [dispatch, employerId, employeeIdHelpText]);

  return (
    <section
      ref={containerRef}
      className="Pet-parent-container"
      id="petParentInfo"
    >
      <h1 className="Pet-parent-title">Pet Parent Info</h1>

      <div className="Pet-parent-input-row">
        <div
          className="Pet-parent-input-container Pet-parent-input-container-left"
        >
          <label
            className="Pet-parent-input-label"
            htmlFor="petParentFirstName"
          >
            {t('petParent.firstName')}

            <input
              ref={petParentNameRef}
              className={'Pet-parent-input '
                + `${nameError ? 'Pet-parent-input-error' : ''}`}
              id="petParentFirstName"
              name="firstName"
              onBlur={onRequiredInputBlur(
                t('petParent.firstNameRequired'), setNameError,
              )}
              onChange={onValueChanged}
              onFocus={onInputFocus(setNameError)}
              placeholder={t('petParent.required')}
              readOnly={isNopComercedDisabled}
              type="text"
              value={newCustomer.name}
            />
          </label>

          <InputError error={nameError} />
        </div>

        <div className="Pet-parent-input-container">
          <label
            className="Pet-parent-input-label"
            htmlFor="petParentLastName"
          >
            {t('petParent.lastName')}

            <input
              className={'Pet-parent-input '
                + `${lastNameError ? 'Pet-parent-input-error' : ''}`}
              id="petParentLastName"
              name="lastName"
              onBlur={onRequiredInputBlur(
                t('petParent.lastNameRequired'), setLastNameError,
              )}
              onChange={onValueChanged}
              onFocus={onInputFocus(setLastNameError)}
              placeholder={t('petParent.required')}
              readOnly={isNopComercedDisabled}
              type="text"
              value={newCustomer.lastName}
            />
          </label>

          <InputError error={lastNameError} />
        </div>
      </div>

      <div className="Pet-parent-input-row">
        <div className={'Pet-parent-input-container '
          + 'Pet-parent-input-container-left'}
        >
          <label
            className="Pet-parent-input-label"
            htmlFor="petParenStreetAddress"
          >
            {t('petParent.streetAddress')}

            <input
              className={'Pet-parent-input '
                + `${streetError ? 'Pet-parent-input-error' : ''}`}
              id="petParenStreetAddress"
              name="streetAddress"
              onBlur={onStreetInputBlur}
              onChange={onStreetValueChanged}
              onFocus={onInputFocus(setStreetError)}
              placeholder={t('petParent.required')}
              readOnly={isNopComercedDisabled}
              type="text"
              value={newCustomer.address.street}
            />
          </label>

          <InputError error={streetError} />
        </div>

        <div className="Pet-parent-input-container">
          <label
            className="Pet-parent-input-label"
            htmlFor="petParenStreetAddress2"
          >
            {t('petParent.streetAddress2')}

            <input
              className="Pet-parent-input"
              id="petParenStreetAddress2"
              name="streetAddress2"
              onBlur={onSecondStreetAddressBlur}
              onChange={onValueChanged}
              placeholder={t('petParent.optionalPlaceholder')}
              readOnly={isNopComercedDisabled}
              type="text"
              value={newCustomer.address.street2}
            />
          </label>
        </div>
      </div>

      <div className="Pet-parent-input-row">
        <Shimmer
          className="Pet-parent-input-container Pet-parent-input-container-left"
          visible={store.isLoadingNewAddress}
          width={282}
        >
          <label
            className="Pet-parent-input-label"
            htmlFor="petParentCity"
          >
            {t('petParent.city')}

            <input
              className={'Pet-parent-input '
                + `${cityError ? 'Pet-parent-input-error' : ''}`}
              id="petParentCity"
              name="city"
              onBlur={onRequiredInputBlur(
                t('petParent.cityRequired'), setCityError,
              )}
              onChange={onValueChanged}
              onFocus={onInputFocus(setCityError)}
              placeholder={t('petParent.cityPlaceholder')}
              readOnly={isNopComercedDisabled}
              type="text"
              value={newCustomer.address.city}
            />
          </label>

          <InputError error={cityError} />
        </Shimmer>

        <div className="Pet-parent-state-zip-container">
          <Shimmer
            className="Pet-parent-state-input-container"
            visible={store.isLoadingNewAddress}
          >
            <label
              className="Pet-parent-input-label"
              htmlFor="petParentState"
            >
              {t('petParent.state')}

              <div className="Pet-parent-input-icon-container">
                <input
                  className="Pet-parent-input-with-icon"
                  id="petParentState"
                  name="state"
                  readOnly
                  type="text"
                  value={newCustomer.address.state}
                />

                <img
                  alt=""
                  className="Pet-parent-lock-icon"
                  src={lockIcon}
                />
              </div>
            </label>
          </Shimmer>

          <Shimmer
            className="Pet-parent-zip-input-container"
            visible={store.isLoadingNewAddress}
          >
            <label
              className="Pet-parent-input-label"
              htmlFor="petParentZipCode"
            >
              {t('petParent.zipCode')}

              <div className="Pet-parent-input-icon-container">
                <input
                  className="Pet-parent-input-with-icon"
                  id="petParentZipCode"
                  name="zipCode"
                  readOnly
                  type="text"
                  value={newCustomer.address.zipCode}
                />

                <img
                  alt=""
                  className="Pet-parent-lock-icon"
                  src={lockIcon}
                />
              </div>
            </label>
          </Shimmer>
        </div>
      </div>

      <div className="Pet-parent-input-row Pet-parent-input-last-row-container">
        <div className="Pet-parent-input-container Pet-parent-input-last-row
        Pet-parent-input-container-left"
        >
          <div
            className="Pet-parent-input-container
              Pet-parent-input-container-left"
          >
            <label
              className="Pet-parent-input-label"
              htmlFor="petParentPhoneNumber"
            >
              {t('petParent.mobilePhone')}

              <input
                className={'Pet-parent-input '
                  + `${phoneNumberError ? 'Pet-parent-input-error' : ''}`}
                id="petParentPhoneNumber"
                maxLength={10}
                name="phoneNumber"
                onBlur={onPhoneNumberBlur}
                onChange={onPhoneNumberChanged}
                onFocus={onInputFocus(setPhoneNumberError)}
                placeholder={t('petParent.required')}
                type="text"
                value={newCustomer.phoneNumber}
              />
            </label>

            <InputError error={phoneNumberError} />
          </div>
        </div>

        <div className="Pet-parent-input-container Pet-parent-input-last-row">
          <label
            className="Pet-parent-input-label"
            htmlFor="newCustomerEmail"
          >
            {t('petParent.email')}

            <input
              ref={emailRef}
              className={'Pet-parent-input '
                + `${emailError ? 'Pet-parent-input-error' : ''}`}
              id="newCustomerEmail"
              name="email"
              onBlur={onBlurEmail}
              onChange={onValueChanged}
              onFocus={onInputFocusEmail(NEW_CUSTOMER_EMAIL_ERROR_CHANGED)}
              placeholder={t('petParent.required')}
              readOnly={nopCommerceUser.LoginSuccess || isLoading}
              required
              type="text"
              value={newCustomer.email}
            />
          </label>

          <InputErrorLabel
            className="Pet-parent-email-label-error"
            error={emailError}
          />
        </div>

        <div className="Pet-parent-input-container Pet-parent-input-last-row">
          <p className="Pet-parent-email-info-paragraph
            Pet-parent-paragraph-container"
          >
            {t('petParent.emailDescription')}
          </p>
        </div>

        {shouldHideEmployeeField(employeeIdHelpText) ? null : (
          <div
            className="Pet-parent-input-container Pet-parent-input-last-row"
          >
            <label
              className="Pet-parent-input-label"
              htmlFor="employeeID"
            >
              {t('petParent.employeeId')}

              {shouldHideEmployeeInfoBubble(employeeIdHelpText) ? null : (
                <span className="Employee-id-info">
                  <IconTooltip description={store.employeeIdHelpText} />
                </span>
              )}

              <input
                className={'Pet-parent-input '
                  + `${employeeError ? 'Pet-parent-input-error' : ''}`}
                id="employeeID"
                maxLength={25}
                onBlur={onEmployeeIDBlur}
                onChange={onEmployeeChanged}
                onFocus={onInputFocus(setEmployeeError)}
                placeholder={t('petParent.required')}
                title={t('petParent.employeeId')}
                type={showEmployeeID ? 'text' : 'password'}
                value={newCustomer.employeeID}
              />
            </label>

            <TouchableDiv
              ariaProps={{
                'aria-label': ' Employee/Member ID',
                title: 'Emplyee ID',
              }}
              className="Employee-id-info-button"
              id="employeeIDVisibility"
              onClick={toogleShowEmployeeID}
            >
              <img
                alt="employee info"
                src={showEmployeeID ? hidePasswordIcon : showPasswordIcon}
              />
            </TouchableDiv>

            <InputError
              error={employeeError}
              fieldName="Employee/Member ID"
            />
          </div>
        )}
      </div>

      {renderSecondaryPetParentToggle()}

      {renderSecondaryPetParentForm()}
    </section>
  );
};

export { PetParentInfo };
