import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';

import { currencyFormat } from '../../../util';
import { BILLING_CYCLE_TYPE } from '../../../constants';
import calendarIcon from '../../../assets/yourInfo/calendar-icon.svg';

const YourFirstPayment = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const {
    data,
    isMonthlyPlan,
    paymentMethod: { paymentMethodType },
    rateSummary: { QuoteSummaries },
  } = store;
  const { effectiveDate } = data;
  const day = moment(effectiveDate).format('D');
  const ordinalDay = moment(effectiveDate).format('Do');

  function getTotal() {
    const billingCycle = isMonthlyPlan
      ? BILLING_CYCLE_TYPE.monthly : BILLING_CYCLE_TYPE.annualy;
    const paymentType = paymentMethodType.value;
    const summary = QuoteSummaries
      .find((item) => item.BillingCycle === billingCycle
        && item.PaymentType === paymentType)
      || { MonthlyPremiumTotal: 0, TodaysTotal: 0 };

    return {
      monthlyTotal: currencyFormat({ value: summary.MonthlyPremiumTotal }),
      todaysTotal: currencyFormat({ value: summary.TodaysTotal }),
    };
  }

  function getTotalText() {
    return isMonthlyPlan
      ? (
        <>
          Your account will be charged ${getTotal().monthlyTotal}
          &nbsp;on the&nbsp;
          <b>{ordinalDay} of each month.</b>
        </>
      ) : 'Your policy will renew one year from the effective date.';
  }

  function getCalendarClass() {
    let className = 'Your-info-calendar-number ';

    if (!isMonthlyPlan) {
      className += 'Your-info-calendar-three';
      return className;
    }

    className += day > 9
      ? 'Your-info-calendar-two' : 'Your-info-calendar-one';
    return className;
  }

  function getDaysCalendar() {
    return isMonthlyPlan ? day : 365;
  }

  return (
    <>
      <div className="Your-info-card-div">
        <div className="Your-info-card">
          <div className="Your-info-flex">
            <div aria-hidden="true" className="Your-info-calendar-container">
              <img alt="" src={calendarIcon} />

              <span className={getCalendarClass()}>
                {getDaysCalendar()}
              </span>
            </div>

            <p>
              {getTotalText()}
            </p>
          </div>

          <div className="Your-info-payment">
            <span>First Payment</span>

            <span className="Your-info-total">${getTotal().todaysTotal}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export { YourFirstPayment };
