import React from 'react';
import { useTranslations } from '../../hooks';
import { CoveredElements } from './WhatFigoCoversAccordion';
import './WhatFigoCovers.css';

const WhatFigoCovers = () => {
  const { t } = useTranslations('quoting', 'whatFigoCovers');

  return (
    <div className="what-figo-covers-container">
      <div className="what-figo-covers-box">
        <h2 className="what-figo-covers-title">
          {t('title')}
        </h2>

        <p className="what-figo-covers-subtitle-description">
          {t('subtitle')}
        </p>
      </div>

      <CoveredElements ariaLabel={t('details')} />
    </div>
  );
};

export { WhatFigoCovers };
