import React from 'react';
import FigoLogo from '../../../assets/figo-logo.svg';
import { HeaderIAIC } from '../header/HeaderIAIC';
import './PurchasePage.css';

const PurchasePage = ({
  children,
  success = false,
}) => (
  <div className="Purchase-page-container">
    <HeaderIAIC />

    <div className="Purchase-page-header">
      <img
        alt="Figo Logo"
        className={`Purchase-page-logo ${success
          ? 'Purchase-page-logo-success' : ''}`}
        src={FigoLogo}
      />
    </div>

    <div className="Purchase-page-content-container">
      {children}
    </div>
  </div>
);

export default PurchasePage;
