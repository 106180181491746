import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import ModalContainer from './ModalContainer';
import { forgetPasswordVisibleChanged, setAsHasPassword } from '../../actions';

import './ModalForgetPassword.css';

const ModalForgetPassword = ({ onModalClosed = () => { } }) => {
  const { t } = useTranslation('login');
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const {
    sessionInformation: { forgetPasswordModalVisible },
    customerValidationResponse: { hasPassword },
  } = store;

  useEffect(() => {
    if (forgetPasswordModalVisible) {
      window.open(process.env.REACT_APP_PETCLOUD_URL, '__blank');
    }
  }, [forgetPasswordModalVisible]);

  const handleCloseModal = useCallback(() => {
    dispatch(forgetPasswordVisibleChanged({ visible: false }));
    dispatch(setAsHasPassword());
    onModalClosed();
  }, [dispatch, onModalClosed]);

  return (
    <ModalContainer
      cancelable={false}
      show={forgetPasswordModalVisible}
    >
      <div className="Modal-forget-password-container">
        <span className="Modal-forget-password-title">
          {hasPassword
            ? t('forgotPassword.titleReset')
            : t('forgotPassword.titleSet')}
        </span>

        <span className="Modal-forget-password-description Inter-regular-16">
          {t('forgotPassword.redirection', {
            action: hasPassword ? t('restore') : t('set'),
          })}

          <br aria-hidden />

          <Trans
            components={{
              a: (
                <a
                  aria-label={t('privacyLink')}
                  className="Modal-add-pet-links Modal-add-pet-link-margin"
                  href={process.env.REACT_APP_PETCLOUD_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            }}
            i18nKey="forgotPassword.retry"
            ns="login"
          />
        </span>

        <div className="Modal-forget-password-footer-container">
          <button
            className={'Modal-forget-password-button-footer-reset '
              + 'Inter-semi-bold-16'}
            onClick={handleCloseModal}
            type="button"
          >
            {t('forgotPassword.ok')}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ModalForgetPassword;
