import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import discountTag from '../../../assets/landing/discount-tag.svg';

import './DiscountApplied.css';

const DiscountApplied = () => {
  const { t } = useTranslation('quoting');
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const { rateSummary } = store;

  const DiscountTagItem = ({ item }) => (
    <div className="Row Discount-applied-item">
      <img
        alt=""
        className="Discount-tag-icon"
        src={discountTag}
      />

      <span>{item.Percentage} {item.Description}</span>
    </div>
  );

  if (rateSummary.AllDiscounts.length === 0) {
    return null;
  }

  return (
    <div className="Discount-applied-container">
      <h3 className={'Text-title Discount-applied-title'
        + ' Text-center'}
      >
        {t('discount', { count: rateSummary.AllDiscounts.length })}
      </h3>

      <div className="Row Discount-applied-items">
        {rateSummary.AllDiscounts.map((item, index) => (
          <DiscountTagItem key={String(index)} index={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default DiscountApplied;
