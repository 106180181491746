import React, { useRef, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import calendarIcon from '../../../assets/yourInfo/calendar-icon-dates.svg';
import './ImportantDatesNewDesign.css';

const DISCLAIMER_TEXT_ID = 3;

const ImportantDates = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const contentRef = useRef();
  const [contentLoaded, setContentLoaded] = useState();
  const [importantDatesContent, setImportantDatesContent] = useState({});
  const { t } = useTranslation('quoting');

  const { data: { insuranceProductEB: {
    InsuranceInformationSectionsEB,
  } } } = store;

  useEffect(() => {
    if ((contentRef.current && !contentLoaded)
      || JSON.stringify(importantDatesContent)
        !== JSON.stringify(InsuranceInformationSectionsEB)) {
      const disclaimerText = InsuranceInformationSectionsEB
        .find((item) => item.InsuranceInformationTypeEBId
          === DISCLAIMER_TEXT_ID)
        || { InsuranceInformationDetailsEB: [{}] };

      const htmlContent = disclaimerText.InsuranceInformationDetailsEB[0].Text;
      const htmlContainer = document.createElement('div');
      htmlContainer.innerHTML = htmlContent;

      setContentLoaded(true);
      setImportantDatesContent(InsuranceInformationSectionsEB);

      contentRef.current.innerHTML = '';
      contentRef.current.appendChild(htmlContainer);
    }
  }, [
    contentLoaded,
    importantDatesContent,
    InsuranceInformationSectionsEB,
  ]);

  function getImportanDate() {
    // TODO: work arround, open saved quote effective date is wrong
    const { effectiveDate } = store.data;

    const isValidDate = new Date(`${effectiveDate} 12:00:00`) > new Date();

    if (isValidDate) {
      return moment(effectiveDate);
    }

    return moment(Date.now()).add(1, 'days');
  }

  function renderImportantDatesList() {
    const { data } = store;
    const importantDates = data.insuranceProductEB.InsuranceWaitingPeriodsEB;
    const effectiveDate = getImportanDate();

    return importantDates.map((date) => {
      const dateText = moment(effectiveDate)
        .add(date.Units, date.IsMonth ? 'months' : 'days')
        .format('MMMM D, YYYY');

      return (
        <div key={String(date.Id)} className="Important-dates-invdividual-card">
          <img
            alt=""
            className="Important-dates-invdividual-card-icon"
            src={calendarIcon}
          />

          <div className="Important-dates-invdividual-card-col">
            <span className="Important-dates-invdividual-card-title">
              {date.InsuranceWaitingPeriodTypeEB.Name}
            </span>

            <span className="Important-dates-invdividual-card-description">
              {dateText}
            </span>
          </div>
        </div>
      );
    });
  }

  if (store
    && store.data
    && store.data.insuranceProductEB
    && store.data.insuranceProductEB.InsuranceWaitingPeriodsEB) {
    return (
      <div className="Important-dates-main-card">
        <h3 className="Important-dates-title-header">
          {t('importantDates.title')}
        </h3>

        <div className="Important-dates-cards-container">
          {renderImportantDatesList()}
        </div>

        <span ref={contentRef} className="Important-dates-description" />
      </div>
    );
  }

  return null;
};

export default ImportantDates;
