import AmericanStates from './utils/americanStates.json';

export const PetGender = {
  Female: {
    name: 'Female',
    value: 2,
  },
  Male: {
    name: 'Male',
    value: 1,
  },
  None: {
    name: '',
    value: -1,
  },
};

export const PetType = {
  Cat: {
    name: 'Cat',
    value: 1,
  },
  Dog: {
    name: 'Dog',
    value: 0,
  },
  None: {
    name: '',
    value: -1,
  },
};

export const QuotingSteps = {
  SelectPlan: {
    name: 'Select Plan',
    value: 1,
  },
  Welcome: {
    name: 'Welcome',
    value: 3,
  },
  YourInfo: {
    name: 'Your Info',
    value: 2,
  },
};

export const AccountTypes = [
  { label: 'Checking', value: 1 },
  { label: 'Savings', value: 2 },
];

export const PAYMENT_TYPE = {
  bank: {
    name: 'Bank Account',
    value: 1,
  },
  creditCard: {
    name: 'Credit or Debit',
    value: 2,
  },
};

export const CreditCardType = {
  AmericanExpress: {
    name: 'American Express',
    value: 4,
  },
  DinnersClub: {
    name: 'Diners Club',
    value: 6,
  },
  Discover: {
    name: 'Discover',
    value: 3,
  },
  MasterCard: {
    name: 'Master Card',
    value: 2,
  },
  Other: {
    name: 'Other',
    value: 5,
  },
  Visa: {
    name: 'Visa',
    value: 1,
  },
};

export const DISCOUNT_MODIFIER_TYPE = 4;

export const POWERUP_TYPE = 5;

export const MULTIPET_DISCOUNT = 58;

export const MAX_PET_NAME_LENGTH = 50;

export const EMPLOYEE_GUID_DUMMY = 'fNpIu1x3VUPX0x08';

export const VET_EXAM_FEES_ID = 17;

export const BILLING_CYCLE_TYPE = {
  annualy: 2,
  monthly: 1,
  oneTime: 0,
};

export const YOUR_INFO_STEPS = {
  firstStep: 1,
  secondStep: 2,
};

export const PRODUCT_VALUES = {
  FPI_VALUES: [11, 12, 13, 14, 15, 16, 17, 18],
  IHC_VALUES: [1, 2, 3],
};

// Method getProductType must be used for casting product id from backend.
export const PRODUCT_TYPE = {
  FPI: 2,
  IHC: 1,
  Unknown: -1,
};

export const TAG_COLOR = {
  mintColor: 1,
  navyColor: 2,
};

export const TAG_COLOR_ID = {
  mint: 55,
  navy: 54,
};

export const CUSTOMER_SERVICE_PHONE_NUMBER = '(844) 738-3446';

export const CUSTOMER_SERVICE_MSG_NUMBER = '(844) 262-8133';

export const PURCHASE_CUSTOMER_SERVICE_MSG =
  'We’re here to help. Give us a call at (844) 738-3446.';

export const CUSTOMER_SERVICE_EMAIL = 'support@figopetinsurance.com';

export const ELECTRONIC_DELIVERY_NOTICE =
  'https://figopetinsurance.com/Electronic-Records-Consent';

export const SMS_APP_LINKS = 'Download the Pet Cloud app.\n'
  + 'For iOS: https://appstore.com/petcloud\n'
  + 'For Android https://play.google.com/store/'
  + 'apps/details?id=com.figoinsurance.petcloud';

export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.figoinsurance.petcloud';

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/figo-pet-cloud/id1278520013';

export const PRIVACY_POLICY_LINK =
  'https://figopetinsurance.com/figo-privacy-policy';

/**
 * Post-purchase page.
 */
export const TERMS_OF_USE_LINK_SMS =
  'https://figopetinsurance.com/figo-sms-terms-conditions';

/**
 * Landing and quoting page (footer component).
 */
export const TERMS_OF_USE_LINK_GENERAL =
  'https://figopetinsurance.com/what-are-figos-terms-use';

export const UNDERWRITING_LINK =
  'https://figopetinsurance.com/carrier-underwriter-information ';

export const INSURED_DISCLOSURES_LINK =
  // eslint-disable-next-line max-len
  'https://assets.ctfassets.net/wcjeikrx8553/3ZavA3DUxHeitwJfp0LLPJ/282309a9e40b0d002b9c5d0f2b1dde63/Figo_Model_Law_Disclosures_ME_DE_MS_NE_NH_LA_WA.pdf';

export const WAITING_PERIODS_LINK =
  // eslint-disable-next-line max-len
  'https://assets.ctfassets.net/wcjeikrx8553/3dkJZXQcANjR0Ou2xC4qRa/634c5082b6b06a141c6a8fcf5526b67b/IAIC_FPI_WP-WAIVER_UW_12.4_SAMPLE_ONLY.pdf';

export const SAMPLE_POLICY_LINK = 'https://figopetinsurance.com/sample-policy';

export const LANDING_DISCLAIMER =
  'Coverage underwritten by Independence American Insurance Company, 11333 '
  + 'North Scottsdale Road Suite 160 Scottsdale, AZ 85254. Plans administered '
  + 'by Figo Pet Insurance, LLC (CA agency #0K02763), 540 N Dearborn Street '
  + '#10873, Chicago, IL 60610. See policy/certificate for details on coverage,'
  + ' terms, limitations, and conditions. *Pre-existing condition coverage '
  + 'may require 12-month waiting period. Waiting period may be waived for '
  + 'groups over 200 employees with prior coverage for Accident & Illness '
  + 'plans. Participation in this plan is voluntary and not subject to ERISA.';

export const FOOTER_TEXT_BASE =
  `No one is permitted to sell, solicit, or negotiate an insurance policy 
  without a producer license in the state in which the plan is sold, and all 
  prospects should be directed to Figo Pet Insurance. The information contained 
  in this website is for illustrative purposes only and coverage under any pet 
  insurance policy is expressly subject to the conditions, restrictions, 
  limitations, exclusions (including pre-existing conditions), and terms of 
  the policy documentation issued by the insurer. Availability of this program 
  is subject to each state’s approval and coverage may vary by state. Coverage 
  underwritten by Independence American Insurance Company (IAIC), a Delaware 
  Insurance Company,11333 North Scottsdale Road Suite 160 Scottsdale, AZ 85254. 
  Plans administered by Figo Pet Insurance, LLC., 540 N Dearborn Street #10873, 
  Chicago, IL 60610. Live Vet and the Figo Pet Cloud are separate non-insurance 
  services unaffiliated with IAIC. Copyright © 2015-${new Date().getFullYear()} 
  Figo Pet Insurance LLC. All rights reserved. Figo Pet Insurance's California 
  license number is 0K02763.`;

export const MODAL_RELATION_LIST = {
  NEED_TO_KNOW_MODAL_ID: 1,
  PET_CO_PAY_MODAL_ID: 4,
  TERMS_AND_COND_MODAL_ID: 3,
  WELLNESS_MODAL_ID: 2,
};

export const MODAL_DEFAULT_CATEGORY = {
  NEED_TO_KNOW_DEF_CATEGORY_ID: 1,
  PET_CO_PAY_DEF_CATEGORY_ID: 17,
  TERMS_AND_COND_DEF_CATEGORY_ID: 8,
  WELLNESS_DEF_CATEGORY_ID: 11,
};

export const PowerUpsRelation = {
  EXTRA_CARE_PACK_PSM_ID: 70427,
  PER_INCIDENT_CO_PAY: 100005,
  VETERINARY_EXAM_FEES_PSM_ID: 70422,
  WELLNESS_DENTAL_PSM_ID: 70430,
  WELLNESS_PSM_ID: 70428,
};

export const BENEFIT_TYPE = {
  bundle: 2,
  individual: 1,
  none: 0,
};

export const BENEFIT_FEE_TYPE = {
  dynamic: 3,
  fixedFee: 2,
  ftpFactor: 1,
  none: 0,
};

export const USER_ROLL_REGISTERED = 'Registered';
export const USER_ROLL_LIST = ['Administrators', USER_ROLL_REGISTERED];

export const TEN_DIGIT_NUMBER = /^\d{10}$/;

export const CVV_CARD_LENGTH_MAX = 4;

export const CVV_CARD_LENGTH_MIN = 3;

export const ONE_INC_PAYMENT_CATEGORY = {
  bankAccount: 'ACH',
  creditCard: 'CreditCard',
};

export const ONE_INC_DEFAULT_TOKEN = 'DEFAULT';

export const ONE_INC_EMPTY_ACH = {
  accountType: '',
  bankName: '',
  customerName: '',
  lastFourDigits: '',
  paymentCategory: '',
  sessionId: '',
  timeZone: '',
  tokenId: '',
  transactionDate: '',
};

export const ONE_INC_EMPTY_CC = {
  accountType: 'Credit Card',
  cardExpirationMonth: 0,
  cardExpirationYear: 0,
  cardType: '',
  customerName: '',
  holderZip: '',
  lastFourDigits: '',
  paymentCategory: '',
  timeZone: '',
  tokenId: '',
  transactionDate: '',
};

export const ONE_INC_PAYMENT_METHOD = {
  bankAccount: 2,
  creditCard: 1,
};

export const DROPDOWN_ITEM_TYPE = {
  addPet: 0,
  header: 1,
  pet: 2,
  remove: 3,
};

export const WELLNESS_MULTIMODAL_ID = {
  BASIC: 15,
  PLUS: 16,
};

export const WELLNESS_COVERAGE_LIMIT_FPI = {
  BASIC: [41, 42],
  PLUS: [43, 44],
};

export const AMERICAN_STATES = AmericanStates;

export const AFTER_RATE_TAG = {
  addPet: 'addPet',
  createRate: 'createRate',
  removePet: 'removePet',
  updatePet: 'updatePet',
  updatePowerUp: 'updatePowerUp',
};

export const RED_BACKGROUND = 2;

export const MARKETING_CHANNEL = {
  AKC: 3,
  Figo: 1,
  PC: 4,
  PPI: 2,
};

export const SAVE_QUOTE_TYPE = {
  auto: 2,
  customer: 1,
};

export const PURCHASE_MULTI_STATUS = {
  complete: 'complete',
  error: 'error',
  pending: 'pending',
  progress: 'progress',
};

export const PURCHASE_ERROR_TYPES = {
  otherError: 'Other',
  paymentMethodError: 'Payment',
};

export const STICKY_BAR_HEIGHT_SECOND_STEP = 96;
export const DEFAULT_STICKY_BAR_HEIGHT = 0;
