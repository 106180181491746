import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loadEmbeddedScript } from '../util';

const AB_TESTING_PARAM = 'ab';

const useAdobeTarget = () => {
  const location = useLocation();
  const locationParams = new URLSearchParams(location?.search || '');
  let abTestingValue = '';
  let useABTesting = false;

  if (locationParams.size) {
    abTestingValue = locationParams.get(AB_TESTING_PARAM);
    useABTesting = /true/i.test(abTestingValue);
  }

  useEffect(() => {
    // will run only on mount because the ab param will not be added by code
    if (useABTesting) {
      loadEmbeddedScript({
        scriptAsync: true,
        // eslint-disable-next-line max-len
        scriptSrc: `https://assets.adobedtm.com/1dfc778c2201/4809bda6a389/${process.env.REACT_APP_ADOBE_TARGET}`,
        toHead: true,
      });

      loadEmbeddedScript({
        scriptInnerHTML: `
          //prehiding snippet for Adobe Target with asynchronous tags deployment
          ; (function (win, doc, style, timeout) {
            var STYLE_ID = 'at-body-style';
            function getParent() {
              return doc.getElementsByTagName('head')[0];
            }

            function addStyle(parent, id, def) {
              if (!parent) {
                return;
              }

              var style = doc.createElement('style');
              style.id = id;
              style.innerHTML = def;
              parent.appendChild(style);
            }

            function removeStyle(parent, id) {
              if (!parent) {
                return;
              }

              var style = doc.getElementById(id);
              if (!style) {
                return;
              }
              parent.removeChild(style);
            }

            addStyle(getParent(), STYLE_ID, style);
            setTimeout(function () {
              removeStyle(getParent(), STYLE_ID);
            }, timeout);
          }(window, document, "body {opacity: 0 !important}", 3000));`,
        toHead: true,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { useABTesting };
};

export { useAdobeTarget };
