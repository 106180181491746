import React, { useState, useRef } from 'react';
import { useResponsive } from '../../hooks/useResponsive';

import './TooltipContainer.css';

const TooltipContainer = (props) => {
  const { description } = props;
  const { isMobile } = useResponsive();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const containerRef = useRef();

  const containerLocation = () => {
    if (!containerRef.current) {
      return {};
    }

    const { clientHeight } = containerRef.current;

    return {
      left: isMobile ? '-60px' : '0px',
      opacity: tooltipVisible ? 1 : 0,
      top: `-${clientHeight}px`,
    };
  };

  const handleHoverIn = () => {
    setTooltipVisible(true);
  };

  const handleHoverOut = () => {
    setTooltipVisible(false);
  };

  function arrowStyle() {
    const arrowLocation = isMobile ? 'end' : props.arrowPosition;

    switch (arrowLocation) {
      case 'end':
        return 'Tooltip-arrow-end';

      case 'center':
        return 'Tooltip-arrow-center';

      case 'start':
        return 'Tooltip-arrow-start';

      default:
        return 'Tooltip-arrow-center';
    }
  }

  function renderTooltip() {
    return (
      <div
        ref={containerRef}
        className="Tooltip-main-container"
        style={containerLocation()}
      >
        <div className="Tooltip-description-container">
          <span className="Tooltip-description">{description}</span>
        </div>

        <div className={`Tooltip-arrow-container ${arrowStyle()}`}>
          <div className="Tooltip-arrow-down" />
        </div>
      </div>
    );
  }

  return (
    <div className="Tooltip-container">
      {renderTooltip()}

      <div
        className="Tooltip-icon-default"
        onMouseEnter={handleHoverIn}
        onMouseLeave={handleHoverOut}
        style={props.styleContent || {}}
      >
        <>
          {props.children}
        </>
      </div>
    </div>
  );
};

export default TooltipContainer;
